/* eslint-disable @typescript-eslint/no-explicit-any */
import { OperationVariables } from '@apollo/client/core';
import IGraphQLReactTools from './IGraphQLReactTools';
import {
  ClientUseLazyQueryResult,
  ClientUseMutationResult,
  ClientUseQueryResult
} from '../services/GraphQLService/types';
import * as T from './types';
import { fakeGql } from './fakeGql';
import { throwGraphQLProviderMissingError } from './NativeAppleAndroidGraphQLReactTools/helpers/throwGraphQLProviderMissingError';
import { getQueryRequestService } from 'src/services/QueryRequestService';
import {
  QueryCallback,
  QueryError,
  QueryRequest
} from 'src/services/QueryRequestService/types';

/**
 * Will receive the React via createGraphQLProvider
 */
let _React: T.ReactType;

export const hasReact = () => {
  return !!_React;
};

const NativeGraphQLReactTools: IGraphQLReactTools = {
  /**
   * useQuery for windows
   */
  useQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [result, setResult] = _React.useState<T.IUseQueryResponse>({
      data: undefined,
      loading: true,
      error: undefined
    });

    // We must protect against new variables objects with the same values
    const [variables, setVariables] = _React.useState<OperationVariables>(
      options?.variables
    );
    _React.useEffect(() => {
      // Shallow compare and update if something is different.
      for (const key in options?.variables) {
        if (options?.variables[key] !== variables[key]) {
          return setVariables(options?.variables);
        }
      }
    }, [options]);

    _React.useEffect(() => {
      const queryRequestService = getQueryRequestService();

      const handleMessage: QueryCallback = (response) => {
        setResult({
          loading: false,
          data: response.data,
          error: response.errors ? response.errors[0] : undefined
        });
      };

      const queryID = queryRequestService.register(
        {
          query,
          variables,
          type: T.MessageType.query
        },
        handleMessage
      );
      return () => {
        queryRequestService.unregister(queryID, handleMessage);
      };
    }, [query, variables]);

    return result;
  },

  /**
   * useMutation for windows
   */
  useMutation<TData = Record<string, any>>(
    mutation: string,
    options?: { variables: OperationVariables }
  ): ClientUseMutationResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [data, setData] = _React.useState<any>();
    const [loading, setLoading] = _React.useState<boolean>(false);
    const [error, setError] = _React.useState<QueryError>();
    const [called, setCalled] = _React.useState(false);

    const mutate = (additionalOptions?: { variables: any }) => {
      setLoading(true);
      setCalled(true);
      setError(undefined);

      const variables = Object.assign(
        {},
        options?.variables,
        additionalOptions?.variables
      );
      const queryRequestService = getQueryRequestService();
      const messageData: QueryRequest = {
        query: mutation,
        variables,
        type: T.MessageType.mutation
      };

      queryRequestService.send(messageData, (response) => {
        if (response.errors) {
          setError(response.errors[0]);
        }
        setLoading(false);
        setData(response.data);
      });
    };

    return [mutate, { data, loading, error, called }];
  },

  useLazyQuery<TData = Record<string, any>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseLazyQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [result, setResult] = _React.useState<T.IUseQueryResponse>({
      data: undefined,
      loading: true,
      error: undefined
    });
    const [called, setCalled] = _React.useState(false);

    const execute = async (additionalOptions?: { variables: any }) => {
      setResult({ ...result, loading: true });
      setCalled(true);

      const variables = Object.assign(
        {},
        options?.variables,
        additionalOptions?.variables
      );
      const queryRequestService = getQueryRequestService();
      const messageData: QueryRequest = {
        query,
        variables,
        type: T.MessageType.query
      };

      queryRequestService.register(messageData, (response) => {
        if (response.errors) {
          setResult({ ...result, loading: false, error: response.errors[0] });
        }
        setResult({
          loading: false,
          data: response.data,
          error: response.errors ? response.errors[0] : undefined
        });
      });
    };

    return [execute, { ...result, called }];
  },

  /**
   * In Native, we don't have the ApolloProvider and we don't create a Provider.  */
  createGraphQLProvider(React: T.ReactType) {
    _React = React;
    const GraphQLProvider = ({ children }: T.GraphQLProviderParams) => {
      return React.createElement(React.Fragment, { children }, children);
    };
    return GraphQLProvider;
  },
  gql: fakeGql
};

export default NativeGraphQLReactTools;
