/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloProvider,
  useQuery as useQueryApollo,
  useMutation as useMutationApollo,
  useLazyQuery as useLazyQueryApollo
} from '@apollo/client/react';
import { OperationVariables, gql } from '@apollo/client/core';
import {
  ClientUseLazyQueryResult,
  ClientUseMutationResult,
  ClientUseQueryResult
} from '../services/GraphQLService/types';
import { fakeGql } from './fakeGql';
import { GraphQLProviderParams, ReactType } from './types';
import GraphQLReactTools from './IGraphQLReactTools';

const WebGraphQLReactTools: GraphQLReactTools = {
  useQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    // TODO: I would check if query is already gql and throw warning
    const result = useQueryApollo(gql(query), options);
    return result;
  },
  useMutation<TData = Record<string, unknown>>(
    mutation: string,
    options?: { variables: OperationVariables }
  ): ClientUseMutationResult<TData> {
    const result = useMutationApollo(gql(mutation), options);
    return result as ClientUseMutationResult<TData>;
  },
  useLazyQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseLazyQueryResult<TData> {
    const result = useLazyQueryApollo(gql(query), options);
    return result;
  },
  createGraphQLProvider(react: ReactType) {
    // I don't like this closure, but I don't have a better idea for now
    const GraphQLProvider = ({ children, client }: GraphQLProviderParams) => {
      return react.createElement(
        ApolloProvider,
        // TODO: this _apolloClient sounds like a private property, I would check if it is a good idea to use it this way
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { client: (client as any)._apolloClient, children },
        children
      );
    };
    return GraphQLProvider;
  },
  gql: fakeGql
};

export default WebGraphQLReactTools;
