import { GraphQLProviderParams, ReactType } from '../types';
import { ApolloError, OperationVariables, gql } from '@apollo/client';
import {
  ClientFetchResult,
  ClientUseQueryResult
} from 'src/services/GraphQLService/types';
import {
  NativeAppleAndroidClientUseLazyQueryResult,
  NativeAppleAndroidClientUseMutationResult,
  NativeAppleAndroidGraphQLService
} from './types';
import { getNativeAppleAndroidApolloClientBody } from './helpers/getNativeAppleAndroidApolloClientBody';
import { GraphQLError } from 'graphql';
import { fakeGql } from '../fakeGql';
import { throwGraphQLProviderMissingError } from './helpers/throwGraphQLProviderMissingError';
import { throwMissingMfeGraphqlServiceError } from './helpers/throwMissingMfeGraphqlServiceError';
import IGraphQLReactTools from '../IGraphQLReactTools';

let _React: ReactType;

export const createNativeAppleAndroidGraphQLReactTools = ({
  JWeb
}): IGraphQLReactTools => {
  function createGraphQLProvider(React: ReactType) {
    _React = React;
    const GraphQLProvider = ({ children }: GraphQLProviderParams) => {
      return React.createElement(React.Fragment, {}, children);
    };
    return GraphQLProvider;
  }

  /**
   * useQuery - Use this hook to query from native Apple/Android layer of the app via the GraphQL NB API.
   * @param query The query to be executed
   * @param options The options to be passed to the query
   * @returns The result of the query
   */
  function useQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [data, setData] = _React.useState<TData>();
    const [error, setError] = _React.useState<ApolloError | GraphQLError>();
    const [loading, setLoading] = _React.useState<boolean>(true);
    let mfeApolloClient: NativeAppleAndroidGraphQLService<TData>;
    const optionsRef = _React.useRef(options);
    optionsRef.current = options;

    try {
      mfeApolloClient = JWeb.Plugins.MfeGraphqlService;

      if (!mfeApolloClient) {
        throwMissingMfeGraphqlServiceError('useQuery');
      }
    } catch (error) {
      throwMissingMfeGraphqlServiceError('useQuery');
    }

    _React.useEffect(() => {
      setError(undefined);
      setLoading(true);
      const body = getNativeAppleAndroidApolloClientBody(
        gql(query),
        optionsRef.current
      );

      try {
        const subscriptionId = mfeApolloClient.subscribe(body, (res) => {
          setData(res.data);
          setError(res.error || res.errors?.[0]);
          setLoading(false);
        });

        return () => mfeApolloClient.unsubscribe({ subscriptionId });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }, [query]);

    return { data, error, loading };
  }

  /**
   * useLazyQuery - Use this hook to lazy query from native Apple/Android layer of the app via the GraphQL NB API.
   * @param query The query to be executed
   * @param options The options to be passed to the query
   * @returns A tuple in the form of `[execute, result]`
   */
  function useLazyQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): NativeAppleAndroidClientUseLazyQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [data, setData] = _React.useState<TData>();
    const [error, setError] = _React.useState<ApolloError | GraphQLError>();
    const [loading, setLoading] = _React.useState<boolean>(false);
    const [called, setCalled] = _React.useState<boolean>(false);
    const [subscriptionId, setSubscriptionId] = _React.useState<string>();
    let mfeApolloClient: NativeAppleAndroidGraphQLService<TData>;
    const optionsRef = _React.useRef(options);
    optionsRef.current = options;

    try {
      mfeApolloClient = JWeb.Plugins.MfeGraphqlService;

      if (!mfeApolloClient) {
        throwMissingMfeGraphqlServiceError('useLazyQuery');
      }
    } catch (error) {
      throwMissingMfeGraphqlServiceError('useLazyQuery');
    }

    const execute = _React.useCallback(
      (additionalOptions?: { variables: OperationVariables }) => {
        setLoading(true);
        setError(undefined);
        setCalled(true);

        const executeOptions = { ...optionsRef.current };
        if (additionalOptions) {
          for (const option in additionalOptions) {
            executeOptions[option] = executeOptions[option]
              ? { ...executeOptions[option], ...additionalOptions[option] }
              : additionalOptions[option];
          }
        }

        const body = getNativeAppleAndroidApolloClientBody(
          gql(query),
          executeOptions
        );

        return new Promise<ClientFetchResult<TData>>((resolve) => {
          try {
            const callbackId = mfeApolloClient.subscribe(body, (res) => {
              setData(res.data);
              setError(res.error || res.errors?.[0]);
              setLoading(false);
              resolve(res);
            });

            setSubscriptionId(callbackId);
          } catch (error) {
            setError(error);
            setLoading(false);
            resolve({ data: undefined, error });
          }
        });
      },
      [query]
    );

    // Unsubscribe when component unmounts
    _React.useEffect(() => {
      return () => {
        if (subscriptionId) {
          mfeApolloClient.unsubscribe({ subscriptionId });
        }
      };
    }, [subscriptionId]);

    return [execute, { data, error, loading, called }];
  }

  /**
   * useMutation - Use this hook to mutate from native Apple/Android layer of the app via the GraphQL NB API.
   * @param query The query to be executed
   * @param options The options to be passed to the query
   * @returns A tuple in the form of `[mutate, result]`
   */
  function useMutation<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): NativeAppleAndroidClientUseMutationResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [data, setData] = _React.useState<TData>();
    const [error, setError] = _React.useState<ApolloError | GraphQLError>();
    const [loading, setLoading] = _React.useState<boolean>(false);
    const [called, setCalled] = _React.useState<boolean>(false);
    let mfeApolloClient: NativeAppleAndroidGraphQLService<TData>;
    const optionsRef = _React.useRef(options);
    optionsRef.current = options;

    try {
      mfeApolloClient = JWeb.Plugins.MfeGraphqlService;

      if (!mfeApolloClient) {
        throwMissingMfeGraphqlServiceError('useMutation');
      }
    } catch (error) {
      throwMissingMfeGraphqlServiceError('useMutation');
    }

    const mutate = _React.useCallback(
      async (additionalOptions?: { variables: OperationVariables }) => {
        setLoading(true);
        setError(undefined);
        setCalled(true);

        const executeOptions = { ...optionsRef.current };
        if (additionalOptions) {
          for (const option in additionalOptions) {
            executeOptions[option] = executeOptions[option]
              ? { ...executeOptions[option], ...additionalOptions[option] }
              : additionalOptions[option];
          }
        }

        const body = getNativeAppleAndroidApolloClientBody(
          gql(query),
          executeOptions
        );
        const result: ClientFetchResult<TData> = {
          data: undefined,
          error: undefined,
          errors: undefined
        };

        try {
          const res = await mfeApolloClient.mutate(body);

          result.data = res.data;
          result.errors = res.errors;
        } catch (error) {
          result.error = error as ApolloError;
        }

        setData(result.data);
        setError(result.error || result.errors?.[0]);
        setLoading(false);

        return result;
      },
      [query]
    );

    return [mutate, { data, error, loading, called }];
  }

  return {
    createGraphQLProvider,
    useQuery,
    useLazyQuery,
    useMutation,
    gql: fakeGql
  };
};
