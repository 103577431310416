/**
 * fakeGql - A template literal tag function that imitates parsing GraphQL query strings into the standard GraphQL AST for IDE syntax highlighting and linting.
 * @param strings The strings of the query
 * @param values The values to be passed to the query
 * @returns Template string
 */
export function fakeGql(
  strings: TemplateStringsArray | string,
  ...values: (string | number)[]
) {
  if (typeof strings === 'string') {
    return strings;
  }

  const result = strings.reduce((prev, current, index) => {
    if (index < values.length) {
      return prev + current + values[index];
    } else {
      return prev + current;
    }
  }, '');

  return result;
}
